import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'

const GalleryPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      
      <div className="w-full py-36 font-verdana flex-grow-0 flex-shrink-0 basis-full relative">
        <div className="relative z-30 flex items-center justify-center text-center w-full h-full">
          <div className="w-10/12 lg:w-2/3">
            <h1 className="font-bold text-4xl lg:text-6xl text-white leading-none">{entry.frontmatter.heading}</h1>
          </div>
        </div>
        <div className="absolute -inset-0 z-20 w-full h-full bg-black opacity-25" /> 
        <div className="absolute -inset-0 z-10 w-full h-full">
          <GatsbyImage className="w-full h-full object-cover" image={image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
        </div>
      </div>

      <div className="w-full pt-12 pb-0">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center relative">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-lg">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pt-12 pb-12">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-12">
            {entry.frontmatter.images.map((entry, i) => {
            const childImage = getImage(entry.image)
              return (
                <div key={`child_${i}`} className="group font-verdana text-md lg:text-lg">
                  <figure>
                    <GatsbyImage image={childImage} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                  </figure>
              </div> 
            )})}
          </div>
        </div>
      </div>
      <div className="w-full py-12 relative">
        <div className="absolute -inset-0">
          <img src="/images/uploads/shutterstock_1083903761.jpg" alt="" className="object-cover object-top w-full h-full block" /> 
        </div>
        <div className="w-10/12 mx-auto max-w-screen-2xl relative">
          <div className="flex justify-around items-center text-center">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-2xl text-center">
                <p>{entry.frontmatter.quote} <strong>&mdash; {entry.frontmatter.author}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default GalleryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        images {
					image {
            childImageSharp {
              gatsbyImageData(width: 1280)
            }
          }
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`